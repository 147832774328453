import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../../lib/firebase";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authenticationLayouts/components/BasicLayout";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Instagram, LinkedIn } from "@mui/icons-material";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import PasswordResetDialog from "dialog/formdialogs/FormDialogPasswordReset";

function GirisYapLayout() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [verificationSent, setVerificationSent] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetError, setResetError] = useState(null);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const createUserDocument = useCallback(async (user, additionalData = {}) => {
    if (!user) return;

    const userRef = doc(db, "users", user.uid);
    const snapshot = await getDoc(userRef);

    if (!snapshot.exists()) {
      const { email, displayName, photoURL } = user;
      const names = displayName ? displayName.split(" ") : ["", ""];
      const createdAt = Date.now();

      try {
        const userInfo = {
          username: displayName || "",
          email: email,
          fullName: names[0] || "" + names.slice(1).join(" ") || "",
          bio: "",
          avatarUrl: photoURL || "",
          role: "user",
          isActive: false,
          createdAt: createdAt,
          updatedAt: createdAt,
          birthday: "",
          emailVerified: true,
          ...additionalData,
        };

        await setDoc(userRef, userInfo);

        // console.log("Kullanıcı başarıyla oluşturuldu");
      } catch (error) {
        console.error("Error creating user document", error);
        throw error;
      }
    }
  }, []);

  const handlePasswordReset = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!resetEmail) {
      setResetError("Lütfen e-posta adresinizi girin.");
      return;
    }

    try {
      // Firestore kontrolü
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", resetEmail));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setResetError("Bu e-posta adresi ile kayıtlı kullanıcı bulunamadı.");
        setResetSuccess(false);
        return;
      }

      await sendPasswordResetEmail(auth, resetEmail, {
        url: window.location.origin + "/giris-yap",
      });

      setResetSuccess(true);
      setResetError(null);

      setTimeout(() => {
        setResetSuccess(false);
        setResetDialogOpen(false); // Dialog'u kapat
        setResetEmail("");
      }, 3000);
    } catch (error) {
      console.error("Password reset error:", error);
      setResetSuccess(false);

      switch (error.code) {
        case "auth/invalid-email":
          setResetError("Geçersiz e-posta adresi.");
          break;
        case "auth/missing-email":
          setResetError("Lütfen e-posta adresinizi girin.");
          break;
        case "auth/too-many-requests":
          setResetError(
            "Çok fazla deneme yaptınız. Lütfen daha sonra tekrar deneyin."
          );
          break;
        case "auth/network-request-failed":
          setResetError(
            "Ağ bağlantısı hatası. İnternet bağlantınızı kontrol edin."
          );
          break;
        default:
          setResetError(
            "Şifre sıfırlama işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin."
          );
      }
    }
  };

  // Dialog'u kapatma fonksiyonu
  const handleCloseDialog = () => {
    setResetDialogOpen(false);
    setResetError(null);
    setResetSuccess(false);
    setResetEmail("");
  };

  useEffect(() => {
    const checkRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result?.user) {
          await createUserDocument(result.user);
          navigate("/ana-sayfa");
        }
      } catch (error) {
        console.error("Redirect result error:", error);
        if (error.code === "auth/unauthorized-domain") {
          setError(
            "Bu domain Google Authentication için yetkilendirilmemiş. Lütfen domain ayarlarını kontrol edin."
          );
        } else {
          setError("Google ile giriş yapılırken bir hata oluştu.");
        }
      }
    };

    checkRedirectResult();
  }, [navigate, createUserDocument]);

  const handleGoogleSignIn = async () => {
    try {
      setError(null);

      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });

      const result = await signInWithPopup(auth, provider);
      if (result.user) {
        await createUserDocument(result.user);
        navigate("/ana-sayfa");
      }
    } catch (error) {
      console.error("Google sign in hatası:", error);

      switch (error.code) {
        case "auth/popup-closed-by-user":
          setError("Google giriş penceresi kapatıldı.");
          break;
        case "auth/popup-blocked":
          setError(
            "Pop-up penceresi engellendi. Sayfayı yenileyin ve tekrar deneyin."
          );
          try {
            await signInWithRedirect(auth, new GoogleAuthProvider());
          } catch (redirectError) {
            console.error("Redirect hatası:", redirectError);
          }
          break;
        case "auth/operation-not-allowed":
          setError(
            "Google ile giriş şu anda devre dışı. Lütfen daha sonra tekrar deneyin."
          );
          break;
        case "auth/network-request-failed":
          setError("Ağ bağlantısı hatası. İnternet bağlantınızı kontrol edin.");
          break;
        case "auth/unauthorized-domain":
          setError("Bu domain Google Authentication için yetkilendirilmemiş.");
          console.error(
            "Firebase Console'da domain yetkilendirmesi yapılmamış!"
          );
          break;
        default:
          const errorMessage = error?.message || "Bilinmeyen bir hata oluştu";
          if (
            typeof errorMessage === "string" &&
            errorMessage.toLowerCase().includes("cross-origin")
          ) {
            setError(
              "Tarayıcı güvenlik politikası nedeniyle işlem başarısız. Sayfayı yenileyin ve tekrar deneyin."
            );
          } else {
            setError(
              `Google ile giriş yapılırken bir hata oluştu: ${errorMessage}`
            );
          }
      }
    }
  };

  // updateEmailVerificationStatus fonksiyonunu da güncelleyelim
  const updateEmailVerificationStatus = async (userId, isVerified) => {
    try {
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        await updateDoc(userRef, {
          emailVerified: isVerified,
        });
      }
    } catch (error) {
      console.error("Firestore güncelleme hatası:", error);
    }
  };

  // Doğrulama e-postası gönderme fonksiyonunu güncelle
  const handleResendVerification = async () => {
    try {
      setError(null);
      setVerificationSent(false);

      // Önce kullanıcıyı tekrar giriş yaptır
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Doğrulama e-postası gönder
      await sendEmailVerification(userCredential.user, {
        url: window.location.origin + "/giris-yap",
        handleCodeInApp: true,
      });

      setVerificationSent(true);
      setError(
        "Doğrulama e-postası gönderildi. Lütfen e-posta kutunuzu kontrol edin."
      );
      setShowResendButton(false);

      // 5 saniye sonra doğrulama mesajını kaldır
      setTimeout(() => {
        setVerificationSent(false);
        setShowResendButton(true);
      }, 5000);
    } catch (error) {
      console.error("Verification error:", error);
      setError(
        "Doğrulama e-postası gönderilirken bir hata oluştu. Lütfen tekrar deneyin."
      );
    }
  };

  // handleLogin fonksiyonunu güncelleyelim
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setVerificationSent(false);
    setShowResendButton(false);

    if (!email || !password) {
      setError("Lütfen e-posta ve şifrenizi girin.");
      return;
    }

    try {
      // Önce giriş yapma denemesi
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Kullanıcının mevcut durumunu yenile
      await user.reload();

      // E-posta doğrulama kontrolü
      if (!user.emailVerified) {
        setError(
          "Giriş yapmadan önce e-posta adresinizi onaylamanız gerekmektedir. Lütfen e-posta kutunuzu kontrol ediniz."
        );
        setShowResendButton(true);
        return;
      }

      // E-posta doğrulanmışsa, Firestore'daki kullanıcı bilgilerini kontrol et
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        setError("Kullanıcı bilgileri bulunamadı.");
        return;
      }

      // Firebase Auth'da doğrulanmış ise Firestore'u da güncelle
      await updateEmailVerificationStatus(user.uid, true);

      // Her şey yolundaysa ana sayfaya yönlendir
      navigate("/ana-sayfa");
    } catch (error) {
      console.error("Login error:", error);

      // Hata mesajlarını güncelle
      switch (error.code) {
        case "auth/user-not-found":
          setError("Bu e-posta adresi ile kayıtlı kullanıcı bulunamadı.");
          break;
        case "auth/wrong-password":
          setError("Hatalı şifre girdiniz.");
          break;
        case "auth/invalid-email":
          setError("Geçersiz e-posta adresi.");
          break;
        case "auth/too-many-requests":
          setError(
            "Çok fazla hatalı giriş denemesi yaptınız. Lütfen daha sonra tekrar deneyin."
          );
          break;
        case "auth/invalid-credential":
          setError(
            "Geçersiz giriş bilgileri. Lütfen e-posta ve şifrenizi kontrol edin."
          );
          break;
        case "auth/missing-password":
          setError("Lütfen şifrenizi girin.");
          break;
        default:
          setError("Giriş yapılırken bir hata oluştu. Lütfen tekrar deneyin.");
      }
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Gkhnmr.com Blog
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="http://www.instagram.com/gkhnmr"
                variant="body1"
                color="white"
                target="_blank"
              >
                <Instagram color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="http://www.github.com/gkhnmr"
                variant="body1"
                color="white"
                target="_blank"
              >
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="https://www.linkedin.com/in/gkhnmr"
                variant="body1"
                color="white"
                target="_blank"
              >
                <LinkedIn color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={1} pb={1} px={3}>
          <MDBox>
            <MDButton
              variant="contained"
              color="error"
              fullWidth
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
            >
              Google ile Devam Et
            </MDButton>
          </MDBox>

          <MDBox mt={1} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              veya e-posta ile giriş yap
            </MDTypography>
          </MDBox>

          <Divider />

          <MDBox component="form" role="form" onSubmit={handleLogin} mt={2}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="E-Posta"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Şifre"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox display="flex" alignItems="center">
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Beni Hatırla
                </MDTypography>
              </MDBox>
              <MDTypography
                variant="button"
                color="info"
                fontWeight="medium"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setResetDialogOpen(true);
                  setResetEmail(email); // Mevcut e-postayı taşı
                }}
              >
                Şifremi Unuttum
              </MDTypography>
              <PasswordResetDialog
                open={resetDialogOpen}
                handleClose={handleCloseDialog}
                resetEmail={resetEmail}
                setResetEmail={setResetEmail}
                handlePasswordReset={handlePasswordReset}
                resetError={resetError}
                resetSuccess={resetSuccess}
              />
            </MDBox>

            {/* Form içindeki ilgili kısım */}
            {error && (
              <MDBox mt={2}>
                <MDTypography
                  variant="caption"
                  color="error"
                  display="block"
                  textAlign="center"
                >
                  {error}
                </MDTypography>
              </MDBox>
            )}

            {showResendButton && (
              <MDBox mt={2}>
                <MDButton
                  variant="text"
                  color="info"
                  fullWidth
                  onClick={handleResendVerification}
                >
                  Doğrulama e-postasını tekrar gönder
                </MDButton>
              </MDBox>
            )}

            {verificationSent && (
              <MDBox mt={2}>
                <MDTypography
                  variant="caption"
                  color="success"
                  display="block"
                  textAlign="center"
                >
                  Doğrulama e-postası gönderildi. Lütfen e-posta kutunuzu
                  kontrol edin.
                </MDTypography>
              </MDBox>
            )}

            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Giriş Yap
              </MDButton>
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography variant="button" color="text">
                Bir hesabın yok mu?{" "}
                <MDTypography
                  component={Link}
                  to="/auth/kaydol"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Kaydol
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default GirisYapLayout;
