import { Reply } from "@mui/icons-material";
import { Collapse, IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import PropTypes from "prop-types";

// Toplam yanıt sayısını hesaplayan yardımcı fonksiyon
const getTotalReplies = (comment) => {
  let count = 0;
  if (comment.replies) {
    count += comment.replies.length;
    comment.replies.forEach((reply) => {
      count += getTotalReplies(reply);
    });
  }
  return count;
};

function CommentItem({ comment, onReply, darkMode, level = 0 }) {
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [showReplies, setShowReplies] = useState(true);

  const totalReplies = getTotalReplies(comment);
  const hasReplies = comment.replies && comment.replies.length > 0;

  const handleReplySubmit = () => {
    onReply(comment.id, replyContent);
    setReplyContent("");
    setShowReplyInput(false);
  };

  return (
    <MDBox ml={{ xs: level * 1, sm: level * 2, md: level * 3 }} maxWidth="100%">
      <MDBox
        mb={1}
        p={1}
        sx={{
          borderRadius: "8px",
          backgroundColor: darkMode ? "rgba(0, 0, 0, 0.2)" : "#f5f5f5",
          borderLeft: level > 0 ? "3px solid" : "none",
          borderLeftColor: "info.main",
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >
        {/* Yazar Bilgisi ve Avatar */}
        <MDBox
          display="flex"
          alignItems="center"
          sx={{
            flexWrap: { xs: "wrap", sm: "nowrap" },
            gap: 1,
          }}
        >
          <MDBox
            display="flex"
            alignItems="center"
            sx={{
              minWidth: 0,
              flex: { xs: "1 1 100%", sm: "0 0 auto" },
            }}
          >
            <MDAvatar
              src={comment.authorAvatarUrl}
              alt={comment.authorName}
              size="sm"
              sx={{ flexShrink: 0 }}
            />
            <MDBox
              flex={1}
              ml={1}
              display="flex"
              flexDirection="column"
              sx={{ minWidth: 0 }}
            >
              <MDTypography
                variant="subtitle1"
                fontWeight="medium"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {comment.authorName}
              </MDTypography>
              <MDTypography
                variant="caption"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {comment.createdAt?.toDate
                  ? new Date(comment.createdAt.toDate()).toLocaleDateString(
                      "tr-TR"
                    )
                  : ""}
              </MDTypography>
            </MDBox>
          </MDBox>

          {/* Yanıt Sayacı */}
          {hasReplies && (
            <MDBox
              display="flex"
              alignItems="center"
              gap={1}
              sx={{
                ml: { xs: 0, sm: "auto" },
                flexShrink: 0,
              }}
            >
              <MDTypography variant="caption" color="info">
                {totalReplies} yanıt
              </MDTypography>
              <IconButton
                size="small"
                onClick={() => setShowReplies(!showReplies)}
              >
                {showReplies ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
              </IconButton>
            </MDBox>
          )}
        </MDBox>

        {/* Yorum İçeriği */}
        <MDTypography
          variant="body2"
          mb={0.5}
          ml={{ xs: 0, sm: 5.5 }}
          sx={{
            mt: 1,
            wordBreak: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            maxWidth: "100%",
          }}
        >
          {comment.content}
        </MDTypography>

        {/* Yanıtla Butonu */}
        <MDBox
          display="flex"
          alignItems="center"
          gap={1}
          ml={{ xs: 0, sm: 5.5 }}
        >
          <MDButton
            variant="text"
            color="info"
            size="small"
            startIcon={<Reply size={16} />}
            onClick={() => setShowReplyInput(!showReplyInput)}
          >
            {hasReplies ? `Yanıtla (${totalReplies})` : "Yanıtla"}
          </MDButton>
        </MDBox>

        {/* Yanıt Formu */}
        <Collapse in={showReplyInput}>
          <MDBox mt={0.5} ml={{ xs: 0, sm: 5.5 }} sx={{ maxWidth: "100%" }}>
            <MDInput
              fullWidth
              multiline
              rows={2}
              placeholder="Yanıtınızı yazın..."
              value={replyContent}
              onChange={(e) => setReplyContent(e.target.value)}
              sx={{
                mb: 1,
                "& .MuiInputBase-root": {
                  maxWidth: "100%",
                },
                "& .MuiInputBase-input": {
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                },
              }}
            />
            <MDBox display="flex" gap={1}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleReplySubmit}
                disabled={!replyContent.trim()}
              >
                Yanıtla
              </MDButton>
              <MDButton
                variant="outlined"
                color="info"
                size="small"
                onClick={() => {
                  setShowReplyInput(false);
                  setReplyContent("");
                }}
              >
                İptal
              </MDButton>
            </MDBox>
          </MDBox>
        </Collapse>

        {/* Yanıt Özeti */}
        {hasReplies && !showReplies && (
          <MDBox
            mt={2}
            ml={{ xs: 0, sm: 5.5 }}
            sx={{
              cursor: "pointer",
              "&:hover": { opacity: 0.8 },
            }}
            onClick={() => setShowReplies(true)}
          >
            <MDTypography variant="caption" color="info">
              {comment.replies.length} doğrudan yanıtı görüntüle{" "}
              {totalReplies > comment.replies.length &&
                `(toplam ${totalReplies} yanıt)`}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>

      {/* Alt Yorumlar */}
      {hasReplies && showReplies && (
        <Collapse in={showReplies}>
          {comment.replies.map((reply) => (
            <CommentItem
              key={reply.id}
              comment={reply}
              onReply={onReply}
              darkMode={darkMode}
              level={level + 0.3}
            />
          ))}
        </Collapse>
      )}
    </MDBox>
  );
}

CommentItem.defaultProps = {
  level: 0,
};

CommentItem.propTypes = {
  comment: PropTypes.object.isRequired,
  onReply: PropTypes.func.isRequired,
  darkMode: PropTypes.bool,
  level: PropTypes.number,
};

export default CommentItem;
