import React from "react";
import { Box, CircularProgress, Typography, Backdrop } from "@mui/material";
import PropTypes from "prop-types";

const MDLoadingScreen = ({ text }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      }}
      open={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "background.paper",
          borderRadius: 2,
          p: 4,
          boxShadow: 3,
        }}
      >
        <CircularProgress
          size={60}
          thickness={4}
          sx={{
            color: "primary.main",
            mb: 2,
          }}
        />
        <Typography
          variant="h6"
          sx={{
            color: "text.primary",
            mt: 2,
            fontWeight: 500,
          }}
        >
          {text}
        </Typography>

        {/* Nokta animasyonu için üç nokta */}
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            mt: 1,
          }}
        >
          {[0, 1, 2].map((i) => (
            <Box
              key={i}
              sx={{
                width: 6,
                height: 6,
                bgcolor: "primary.main",
                borderRadius: "50%",
                animation: "bounce 1.4s infinite ease-in-out",
                animationDelay: `${i * 0.16}s`,
                "@keyframes bounce": {
                  "0%, 100%": {
                    transform: "translateY(0)",
                  },
                  "50%": {
                    transform: "translateY(-10px)",
                  },
                },
              }}
            />
          ))}
        </Box>
      </Box>
    </Backdrop>
  );
};

MDLoadingScreen.defaultProps = {
  text: "Bir saniye...",
};

MDLoadingScreen.propTypes = {
  text: PropTypes.string,
};

export default MDLoadingScreen;
