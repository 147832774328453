// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import Footer from "examples/Footer";


import { useAccount } from "context/AccountContext";
import MDLoadingSkeleton from "components/MDLoadingSkeleton";
import MDErrorDisplay from "components/MDErrorDisplay";
import PropTypes from "prop-types";
import Hakkimda from "./components/Hakkimda";

function HakkimdaLayout({ transparent, light }) {
  const { isLoading, error } = useAccount();

  // Loading durumunda tüm grid için tek bir loading state
  if (isLoading) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <Grid container spacing={2}>
          {[...Array(3)].map((_, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <MDLoadingSkeleton />
            </Grid>
          ))}
        </Grid>
        <Footer />
      </MDBox>
    );
  }

  // Hata durumunda
  if (error) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <MDErrorDisplay message={error} />
      </MDBox>
    );
  }

  return (
    <MDBox
      px={{
        xs: 1,
        sm: transparent ? 0 : 1,
        md: transparent ? 0 : 1,
        xl: transparent ? 0 : 1,
        lg: transparent ? 0 : 1,
      }}
      my={1}
      mx={2}
    >
      <MDBox mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Hakkimda />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

HakkimdaLayout.defaultProps = {
  transparent: false,
  light: false,
};

HakkimdaLayout.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
};

export default HakkimdaLayout;
