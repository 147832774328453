// @mui material components
import Grid from "@mui/material/Grid";
import Carousel from "react-material-ui-carousel"; // Yeni eklenen import

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import Footer from "examples/Footer";

import PropTypes from "prop-types";

import { useAccount } from "context/AccountContext";
import MDLoadingSkeleton from "components/MDLoadingSkeleton";
import MDErrorDisplay from "components/MDErrorDisplay";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Slider Data
import { usePosts } from "hooks/usePosts";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDAlert from "components/MDAlert";
import { useGetPostsQuery } from "services/postApi";
import { useGetFeaturedPostsQuery } from "services/postApi";

function AnaSayfaLayout({ transparent, light }) {
  // İki ayrı sorgu kullanmalıyız
  const {
    data: posts = [], // Varsayılan değer atayarak undefined kontrolü yapıyoruz
    isLoading: postsLoading,
    error: postsError,
  } = useGetPostsQuery();

  const {
    data: featuredPosts = [], // Varsayılan değer atayarak undefined kontrolü yapıyoruz
    isLoading: featuredLoading,
    error: featuredError,
  } = useGetFeaturedPostsQuery();

  const navigate = useNavigate();

  //Postler için basit bir loading error kontrolü
  // Her iki sorgunun da yüklenmesini kontrol ediyoruz
  if (postsLoading || featuredLoading) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox height="400px">
              <MDLoadingSkeleton />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  // Her iki sorgudaki hataları kontrol ediyoruz
  const error = postsError || featuredError;
  if (error) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox height="400px">
              <MDAlert color="error">
                {error.message || "Bir hata oluştu"}
              </MDAlert>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  // Loading durumunda tüm grid için tek bir loading state
  if (postsLoading) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <Grid container spacing={2}>
          {[...Array(3)].map((_, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <MDLoadingSkeleton />
            </Grid>
          ))}
        </Grid>
        <Footer />
      </MDBox>
    );
  }

  // Hata durumunda
  if (error) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <MDErrorDisplay message={error} />
      </MDBox>
    );
  }

  const renderCarousel = () => {
    // featuredPosts undefined kontrolü
    if (!featuredPosts || featuredPosts.length === 0) {
      return null;
    }

    return (
      <MDBox mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox
              sx={{
                borderRadius: "15px",
                overflow: "hidden",
              }}
            >
              <Carousel
                animation="slide"
                autoPlay={true}
                indicators={true}
                navButtonsAlwaysVisible={true}
                cycleNavigation={true}
                interval={5000}
              >
                {featuredPosts.map((post) => (
                  <MDBox
                    key={post.id}
                    sx={{
                      cursor: "pointer",
                      position: "relative",
                      height: { xs: "350px", sm: "450px", md: "550px" },
                      width: "100%",
                      borderRadius: "lg",
                      overflow: "hidden",
                    }}
                    onClick={() => navigate(`/post/${post.slug}`)}
                  >
                    <img
                      src={post.featuredImageUrl}
                      alt={post.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <MDBox
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: 2,
                        background: "rgba(0,0,0,0.5)",
                        color: "white",
                      }}
                    >
                      <MDTypography variant="h5" color="white">
                        {post.title}
                      </MDTypography>
                      <MDTypography variant="body2" color="white">
                        {post.excerpt}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                ))}
              </Carousel>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  };

  const renderPosts = () => {
    // posts undefined kontrolü
    if (!posts) return null;

    return posts.map((post) => (
      <Grid item key={post.id} xs={12} md={6} lg={4} xl={4} xxxl={3} mb={3}>
        <DefaultProjectCard
          image={
            post.featuredImageUrl ||
            "https://images.unsplash.com/photo-1497294815431-9365093b7371?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
          }
          label={post.categoryName || "Genel"} // Varsayılan değer ekledik
          title={post.title}
          description={post.excerpt}
          action={{
            route: `/post/${post.slug}`,
            label: "detay",
            color: "info",
            type: "internal",
          }}
          authors={[
            {
              image: post.authorAvatar,
              name: post.authorName || "Anonim", // Varsayılan değer ekledik
            },
          ]}
        />
      </Grid>
    ));
  };

  return (
    <MDBox
      px={{
        xs: 1,
        sm: transparent ? 0 : 1,
        md: transparent ? 0 : 1,
        xl: transparent ? 0 : 1,
        lg: transparent ? 0 : 1,
      }}
      my={1}
      mx={2}
    >
      {renderCarousel()}
      <MDBox mb={3}>
        <Grid container spacing={2}>
          {renderPosts()}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

AnaSayfaLayout.defaultProps = {
  transparent: false,
  light: false,
};

AnaSayfaLayout.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
};

export default AnaSayfaLayout;
