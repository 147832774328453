import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authenticationLayouts/components/Footer";

function BasicLayout({ image, children }) {
  return (
    <PageLayout>
      <MDBox position="relative" width="100%" height="100vh" overflow="hidden">
        <MDBox
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              image &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />

        <MDBox
          position="relative"
          px={1}
          width="100%"
          height="calc(100vh - 60px)"
          mx="auto"
        >
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              {children}
            </Grid>
          </Grid>
        </MDBox>

        <MDBox position="absolute" bottom={0} width="100%" height="60px">
          <Footer light />
        </MDBox>
      </MDBox>
    </PageLayout>
  );
}

BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
