/**
 * Generates a slug from the given post title.
 * @param {string} title - The title of the post.
 * @returns {string} A slugified string suitable for use in URLs.
 */
export function generateSlug(title) {
  // Türkçe karakter eşleştirme tablosu
  const turkishChars = {
    ğ: "g",
    Ğ: "g",
    ü: "u",
    Ü: "u",
    ş: "s",
    Ş: "s",
    ı: "i",
    İ: "i",
    ö: "o",
    Ö: "o",
    ç: "c",
    Ç: "c",
  };

  return (
    title
      // Önce Türkçe karakterleri dönüştür
      .replace(/[ğĞüÜşŞıİöÖçÇ]/g, (match) => turkishChars[match])
      // Küçük harfe çevir
      .toLowerCase()
      // Başındaki ve sonundaki boşlukları temizle
      .trim()
      // Alfanumerik olmayan karakterleri temizle (tire hariç)
      .replace(/[^a-z0-9\s-]/g, "")
      // Birden fazla boşluğu tek tire ile değiştir
      .replace(/\s+/g, "-")
      // Birden fazla tireyi tek tireye dönüştür
      .replace(/-+/g, "-")
      // Başındaki ve sonundaki tireleri temizle
      .replace(/^-+|-+$/g, "")
  );
}

// Post verilerindeki Timestamp'i dönüştürmek için yardımcı fonksiyon
export function convertFirestoreTimestamps(obj) {
  // Null veya undefined kontrolü
  if (!obj) return obj;

  // Objemizin bir kopyasını oluşturuyoruz
  const converted = { ...obj };

  // Her bir alanı kontrol ediyoruz
  Object.keys(converted).forEach(key => {
    const value = converted[key];

    // Timestamp kontrolü ve dönüşümü
    if (value && typeof value.toDate === 'function') {
      converted[key] = value.toDate().toISOString();
    }
    // Nested objeleri recursive olarak kontrol ediyoruz
    else if (value && typeof value === 'object' && !Array.isArray(value)) {
      converted[key] = convertFirestoreTimestamps(value);
    }
    // Array'leri kontrol ediyoruz
    else if (Array.isArray(value)) {
      converted[key] = value.map(item =>
        typeof item === 'object' ? convertFirestoreTimestamps(item) : item
      );
    }
  });

  return converted;
}

