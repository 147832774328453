import { useEffect, useMemo, lazy, Suspense } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDLoadingScreen from "components/MDLoadingScreen";
import { useMaterialUIController, setOpenConfigurator } from "context";
import { useFirestoreRoutes } from "hooks/useFirestoreRoutes";
import { useAuth } from "AuthContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import useSessionManager from "hooks/useSessionManager";
import PropTypes from "prop-types";

// Lazy loaded components
const Configurator = lazy(() => import("examples/Configurator"));
const AnaSayfaLayout = lazy(() => import("layouts/AnaSayfaLayout"));
const GirisYapLayout = lazy(() =>
  import("layouts/authenticationLayouts/girisyapLayout")
);
const KaydolLayout = lazy(() =>
  import("layouts/authenticationLayouts/kaydolLayout")
);
const MainLayout = lazy(() => import("layouts/MainLayout"));
const StaticPage = lazy(() => import("layouts/StaticPage"));
const AdminBaseLayout = lazy(() => import("admin/layout/AdminBaseLayout"));

// Constants
const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes
const SITE_METADATA = {
  title: "Gkhnmr.com | Web Tasarım ve Yazılım",
  description:
    "Web tasarım ve yazılım dünyasında profesyonel çözümler ve güncel teknoloji bilgileri",
  description2:
    "Web tasarım ve yazılım dünyasında profesyonel çözümler, güncel teknoloji bilgileri ve yazılım geliştirme rehberleri",
  siteName: "Gkhnmr.com",
  keywords:
    "web tasarım, yazılım geliştirme, frontend, backend, react, javascript",
};

function NotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/ana-sayfa", { replace: true });
  }, [navigate]);
  return null;
}

function SEOHelmet({ pathname }) {
  const currentUrl = `${window.location.origin}${pathname}`;

  return (
    <Helmet>
      <title>{SITE_METADATA.title}</title>
      <meta name="description" content={SITE_METADATA.description} />
      <meta name="keywords" content={SITE_METADATA.keywords} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content={SITE_METADATA.siteName} />
      <meta property="og:title" content={SITE_METADATA.title} />
      <meta property="og:description" content={SITE_METADATA.description2} />
      <meta
        property="og:image"
        content="https://firebasestorage.googleapis.com/v0/b/myblog-3a6ee.firebasestorage.app/o/blog-images%2Flogo.svg?alt=media&token=863f3dcb-231a-4831-b62e-3790b1dd92ae"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:locale" content="tr_TR" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={SITE_METADATA.title} />
      <meta name="twitter:description" content={SITE_METADATA.description} />
      <meta
        name="twitter:image"
        content="https://firebasestorage.googleapis.com/v0/b/myblog-3a6ee.firebasestorage.app/o/blog-images%2Flogo.svg?alt=media&token=863f3dcb-231a-4831-b62e-3790b1dd92ae"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Gökhan Emre Kasapoğlu" />
      <link rel="canonical" href={currentUrl} />
    </Helmet>
  );
}

// PropTypes tanımı eklendi
SEOHelmet.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    direction,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { routes: dynamicRoutes, loading, error } = useFirestoreRoutes();

  useSessionManager(SESSION_TIMEOUT);

  const processedRoutes = useMemo(() => {
    if (!dynamicRoutes?.length) {
      return [
        { path: "/ana-sayfa", element: <AnaSayfaLayout />, key: "ana-sayfa" },
      ];
    }
    return dynamicRoutes
      .map((route) => ({
        path: route.route,
        element: route.component,
        key: route.key,
      }))
      .filter(Boolean);
  }, [dynamicRoutes]);

  const sidenavProps = useMemo(
    () => ({
      color: sidenavColor,
      brand:
        (transparentSidenav && !darkMode) || whiteSidenav
          ? "/images/logo-dark.png" // Moved to public folder
          : "/images/logo-white.png", // Moved to public folder
      brandName: "Kategoriler",
      routes: dynamicRoutes || [],
    }),
    [sidenavColor, transparentSidenav, darkMode, whiteSidenav, dynamicRoutes]
  );

  const configsButton = useMemo(
    () => (
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3.25rem"
        height="3.25rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="2rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
        onClick={() => setOpenConfigurator(dispatch, !openConfigurator)}
      >
        <Icon fontSize="small" color="inherit">
          settings
        </Icon>
      </MDBox>
    ),
    [openConfigurator, dispatch]
  );

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (loading) return <MDLoadingScreen />;
  if (error) {
    console.error("Error loading routes:", error);
    return <div>Error loading navigation menu</div>;
  }

  const isAuthPage = pathname.startsWith("/auth");
  const isAdminPanel = pathname.startsWith("/admin");

  return (
    <>
      <SEOHelmet pathname={pathname} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: 9999 }}
      />
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <MDBox>
          <Suspense fallback={<MDLoadingScreen />}>
            {isAdminPanel ? (
              user?.emailVerified ? (
                <AdminBaseLayout />
              ) : (
                <Navigate to="/auth/giris-yap" replace />
              )
            ) : isAuthPage && !user ? (
              <Routes>
                <Route path="/auth/giris-yap" element={<GirisYapLayout />} />
                <Route path="/auth/kaydol" element={<KaydolLayout />} />
                <Route
                  path="/auth/*"
                  element={<Navigate to="/ana-sayfa" replace />}
                />
              </Routes>
            ) : (
              <MainLayout sidenavProps={sidenavProps}>
                <Routes>
                  {processedRoutes.map((route) => (
                    <Route
                      key={route.key}
                      path={route.path}
                      element={route.element}
                    />
                  ))}
                  <Route path="/sayfa/:slug" element={<StaticPage />} />
                  <Route
                    path="/"
                    element={<Navigate to="/ana-sayfa" replace />}
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </MainLayout>
            )}

            {!isAuthPage && (
              <>
                <Configurator />
                {configsButton}
              </>
            )}
          </Suspense>
        </MDBox>
      </ThemeProvider>
    </>
  );
}
