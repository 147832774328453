// AppWrapper.jsx
import { AuthProvider } from "./AuthContext";
import { AccountProvider } from "context/AccountContext";
import App from "./App";

export default function AppWrapper() {
  return (
    <AuthProvider>
      <AccountProvider>
        <App />
      </AccountProvider>
    </AuthProvider>
  );
}
