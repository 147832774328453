import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "lib/firebase";
import { useAuth } from "AuthContext";
import MDLoadingScreen from "components/MDLoadingScreen";

const AccountContext = createContext();

const useFirestoreData = (userId, authLoading) => {
  const [state, setState] = React.useState({
    userAccountInfo: null,
    isLoading: true,
    error: null,
  });

  React.useEffect(() => {
    let mounted = true;

    if (authLoading) {
      setState((prev) => ({
        ...prev,
        isLoading: true,
        error: null,
      }));
      return;
    }

    if (!userId) {
      setState((prev) => ({
        ...prev,
        userAccountInfo: null,
        isLoading: false,
        error: null,
      }));
      return;
    }

    const userDocRef = doc(db, "users", userId);

    const unsubscribe = onSnapshot(
      userDocRef,
      (docSnapshot) => {
        if (!mounted) return;

        try {
          if (docSnapshot.exists()) {
            setState({
              userAccountInfo: {
                uid: docSnapshot.id,
                ...docSnapshot.data(),
              },
              isLoading: false,
              error: null,
            });
          } else {
            setState({
              userAccountInfo: null,
              isLoading: false,
              error: null,
            });
          }
        } catch (error) {
          console.error("Firestore error:", error);
          setState({
            userAccountInfo: null,
            isLoading: false,
            error: `Veri işlenirken hata oluştu: ${error.message}`,
          });
        }
      },
      (error) => {
        if (!mounted) return;
        console.error("Firestore error:", error);
        setState({
          userAccountInfo: null,
          isLoading: false,
          error: `Veritabanı bağlantı hatası: ${error.message}`,
        });
      }
    );

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, [userId, authLoading]);

  // Memoize the state to prevent unnecessary re-renders
  return useMemo(() => state, [state]);
};

export function AccountProvider({ children }) {
  const { user, loading: authLoading } = useAuth();
  const accountData = useFirestoreData(user?.uid, authLoading);

  const contextValue = useMemo(() => accountData, [accountData]);

  if (authLoading || accountData.isLoading) {
    return <MDLoadingScreen text="Bir saniye..." />;
  }

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  );
}

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAccount() {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
}
