import { useState, useEffect } from "react";

// react-router components
import { Link, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

import logoSvg from "assets/images/logo.svg";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import { Menu, MenuItem } from "@mui/material";
import NotificationItem from "examples/Items/NotificationItem";
import { useAuth } from "AuthContext";
import { useAccount } from "context/AccountContext";
import useSessionManager from "hooks/useSessionManager";

function DefaultNavbar({ transparent, light, action }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [menuItems, setMenuItems] = useState([]);
  const { userAccountInfo } = useAccount();

  const location = useLocation(); // Mevcut lokasyonu takip ediyoruz

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [error, setError] = useState(null);

  // Dropdown menu states
  const [openMenu, setOpenMenu] = useState(null);

  const { user } = useAuth();

  const { logout } = useSessionManager();

  const handleLogout = async () => {
    try {
      await logout();
      // Çıkış başarılı olduğunda kullanıcıyı giriş sayfasına yönlendir
    } catch (error) {
      console.error("Logout error", error);
    }
  };

  // Dropdown menu handlers
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  // Firebase'den menü verilerini çeken fonksiyon
  const fetchMenuItems = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "blog"));

      querySnapshot.forEach((doc) => {
        if (doc.id === "navbarMenu") {
          const menuData = doc.data().menuList;
          setMenuItems(menuData || []);
        }
      });
    } catch (error) {
      console.error("Menü verileri çekilirken hata:", error);
      setError(error.message);
    }
  };

  // İki useEffect kullanıyoruz:
  // 1. İlk yüklemede menüyü çekmek için
  useEffect(() => {
    fetchMenuItems();
  }, []);

  // 2. Rota değişikliklerinde menüyü güncellemek için
  useEffect(() => {
    fetchMenuItems();
  }, [location.pathname]); // location.pathname değiştiğinde çalışır

  // Hata durumu için kontrol
  useEffect(() => {
    if (error) {
      console.error("Firebase Hatası:", error);
    }
  }, [error]);

  const openMobileNavbar = ({ currentTarget }) =>
    setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < 1285) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, [mobileView]);

  // Render the notifications menu
  const renderMenu = () => (
    <>
      <MDButton
        variant="text"
        color={light ? "white" : "dark"}
        onClick={handleOpenMenu}
        sx={{
          display: "flex",
          alignItems: "center",
          py: 0.5,
          pl: 0.5,
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0.1)",
          },
        }}
      >
        <Icon sx={{ ml: 1 }}>settings</Icon>
        <Icon sx={{ ml: 1 }}>expand_more</Icon>
      </MDButton>

      <Menu
        anchorEl={openMenu}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          mt: 1,
          "& .MuiPaper-root": {
            borderRadius: "10px",
            minWidth: "200px",
            boxShadow: "0 4px 20px 0 rgba(0,0,0,0.14)",
            padding: "0.5rem 0",
          },
        }}
      >
        {(userAccountInfo?.role === "admin" ||
          userAccountInfo?.role === "editor" ||
          userAccountInfo?.role === "author") &&
        userAccountInfo?.isActive &&
        userAccountInfo?.emailVerified ? (
          [
            <MenuItem
              key="dashboard"
              component={Link}
              to="/admin/dashboard"
              onClick={handleCloseMenu}
            >
              <NotificationItem icon={<Icon>dashboard</Icon>} title="Panel" />
            </MenuItem>,
            <MenuItem key="logout" to="/ana-sayfa" onClick={handleCloseMenu}>
              <NotificationItem
                icon={<Icon>logout</Icon>}
                title="Çıkış Yap"
                onClick={handleLogout}
              />
            </MenuItem>,
          ]
        ) : userAccountInfo?.isActive && !userAccountInfo?.emailVerified ? (
          <MenuItem component={Link} to="/ana-sayfa" onClick={handleCloseMenu}>
            <NotificationItem
              icon={<Icon>logout</Icon>}
              title="Çıkış Yap(E-posta doğrulaması bekleniyor!)"
              onClick={handleLogout}
            />
          </MenuItem>
        ) : (
          <MenuItem component={Link} to="/ana-sayfa" onClick={handleCloseMenu}>
            <NotificationItem
              icon={<Icon>logout</Icon>}
              title="Çıkış Yap"
              onClick={handleLogout}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );

  return (
    <MDBox>
      <MDBox
        py={1}
        px={{ xs: 2, sm: transparent ? 2 : 3, lg: transparent ? 2 : 4 }}
        mt={2}
        mx={2}
        borderRadius="lg"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        left={0}
        zIndex={3}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(darkMode ? background.sidenav : white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <MDBox
          component={Link}
          to="/ana-sayfa"
          py={transparent ? 1.5 : 0.75}
          lineHeight={1}
          pl={{ xs: 0 }}
        >
          <MDBox
            sx={{
              backgroundImage: () => logoSvg && `url(${logoSvg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "230px",
              height: "50px",
            }}
          />
        </MDBox>
        <MDBox
          color="inherit"
          display={{ xs: "none", navbarWidth: "flex" }}
          m={0}
          p={0}
        >
          {menuItems.map((menuItem, index) =>
            menuItem.isNavBarItems ? (
              <DefaultNavbarLink
                key={index}
                icon={menuItem.icon || "donut_large"}
                name={menuItem.name}
                route={menuItem.route || `/${menuItem.name.toLowerCase()}`}
                light={light}
              />
            ) : null
          )}
          {user ? (
            renderMenu()
          ) : (
            <DefaultNavbarLink
              icon="login"
              name="Giriş Yap"
              route={`/auth/giris-yap`}
              light={light}
            />
          )}
        </MDBox>
        {action &&
          (action.type === "internal" ? (
            <MDBox display={{ xs: "none", lg: "inline-block" }}>
              <MDButton
                component={Link}
                to={action.route}
                variant="gradient"
                color={action.color ? action.color : "info"}
                size="small"
              >
                {action.label}
              </MDButton>
            </MDBox>
          ) : (
            <MDBox display={{ xs: "none", lg: "inline-block" }}>
              <MDButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color={action.color ? action.color : "info"}
                size="small"
                sx={{ mt: -0.3 }}
              >
                {action.label}
              </MDButton>
            </MDBox>
          ))}
        <MDBox
          display={{ xs: "inline-block", navbarWidth: "none" }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={openMobileNavbar}
        >
          <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
        </MDBox>
      </MDBox>
      {mobileView && (
        <DefaultNavbarMobile
          open={mobileNavbar}
          close={closeMobileNavbar}
          menuItems={menuItems}
          user={userAccountInfo}
        />
      )}
    </MDBox>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
