// src/hooks/usePosts.js
import { useState, useEffect } from "react";
import { postService } from "../services/postService";

export const usePosts = (categoryId = null) => {
  const [posts, setPosts] = useState([]);
  const [postsCategory, setPostsCategory] = useState([]);
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [postsLoading, setPostsLoading] = useState(true);
  const [postsError, setPostsError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        // Load both regular and featured posts in parallel
        const [postsData, featuredPostsData] = await Promise.all([
          postService.getPosts(),
          postService.getFeaturedPosts(),
        ]);

        if (categoryId) {
          setPostsCategory(
            postsData.filter((post) => post.categoryId === categoryId)
          );
        }

        setPosts(postsData);
        setFeaturedPosts(featuredPostsData);
      } catch (err) {
        setPostsError(err.message);
      } finally {
        setPostsLoading(false);
      }
    };

    loadData();
  }, [categoryId]);

  return { posts, postsLoading, postsError, featuredPosts, postsCategory };
};
