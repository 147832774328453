import { Skeleton } from "@mui/material";
import MDBox from "components/MDBox";

export default function MDLoadingSkeleton() {
    return (
        <MDBox>
            <Skeleton variant="rectangular" height={50} sx={{ mb: 2 }} />
            <Skeleton variant="rectangular" height={100} />
            <MDBox mt={1}>
                {[...Array(3)].map((_, index) => (
                    <Skeleton key={index} height={40} sx={{ my: 1 }} />
                ))}
            </MDBox>
        </MDBox>
    );
}