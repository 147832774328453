import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import PropTypes from "prop-types";

function PasswordResetDialog({
  open,
  handleClose,
  resetEmail,
  setResetEmail,
  handlePasswordReset,
  resetError,
  resetSuccess,
}) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDTypography variant="h4">Şifre Sıfırlama</MDTypography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </MDBox>
      </DialogTitle>

      <DialogContent>
        <MDBox component="form" role="form" onSubmit={handlePasswordReset}>
          <MDTypography variant="body2" color="text" mb={2}>
            E-posta adresinizi girin ve size şifre sıfırlama bağlantısı
            gönderelim.
          </MDTypography>

          <MDBox mb={2}>
            <MDInput
              type="email"
              label="E-Posta"
              fullWidth
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              autoFocus
            />
          </MDBox>

          {resetError && (
            <MDBox mt={2}>
              <MDTypography variant="caption" color="error">
                {resetError}
              </MDTypography>
            </MDBox>
          )}

          {resetSuccess && (
            <MDBox mt={2}>
              <MDAlert color="success">
                Şifre sıfırlama bağlantısı e-posta adresinize gönderildi!
                <br />
                Lütfen e-postanızı kontrol edin ve gelen bağlantıya tıklayarak
                yeni şifrenizi belirleyin.
              </MDAlert>
            </MDBox>
          )}
        </MDBox>
      </DialogContent>

      <DialogActions>
        <MDBox display="flex" gap={2} width="100%" p={1.5}>
          <MDButton
            variant="outlined"
            color="info"
            fullWidth
            onClick={handleClose}
          >
            İptal
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={handlePasswordReset}
          >
            Gönder
          </MDButton>
        </MDBox>
      </DialogActions>
    </Dialog>
  );
}

PasswordResetDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  resetEmail: PropTypes.string.isRequired,
  setResetEmail: PropTypes.func.isRequired,
  handlePasswordReset: PropTypes.func.isRequired,
  resetError: PropTypes.string,
  resetSuccess: PropTypes.bool,
};
export default PasswordResetDialog;
