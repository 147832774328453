import { Alert, AlertTitle } from "@mui/material";
import PropTypes from "prop-types";

function MDErrorDisplay({ message }) {
    return (
        <Alert severity="error" sx={{ mb: 2 }}>
            <AlertTitle>Hata</AlertTitle>
            {message}
        </Alert>
    );
}

MDErrorDisplay.propTypes = {
    message: PropTypes.string.isRequired,
};

export default MDErrorDisplay;