import { Box, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import { useTheme } from "@mui/material/styles";
import MDBox from "components/MDBox";

function MDSocialShare({ url, title, size = "medium" }) {
  const [copied, setCopied] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Icon boyutlarını hesapla
  const getIconSize = () => {
    if (size === "small" || isMobile) return "small";
    if (size === "large") return "large";
    return "medium";
  };

  // Button boyutlarını hesapla
  const getButtonSize = () => {
    if (size === "small" || isMobile) return "30px";
    if (size === "large") return "48px";
    return "40px";
  };

  const shareHandlers = {
    facebook: () => {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`,
        "_blank"
      );
    },
    twitter: () => {
      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          title
        )}&url=${encodeURIComponent(url)}`,
        "_blank"
      );
    },
    whatsapp: () => {
      window.open(
        `https://api.whatsapp.com/send?text=${encodeURIComponent(
          title + " " + url
        )}`,
        "_blank"
      );
    },
    linkedin: () => {
      window.open(
        `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          url
        )}`,
        "_blank"
      );
    },
    telegram: () => {
      window.open(
        `https://telegram.me/share/url?url=${encodeURIComponent(
          url
        )}&text=${encodeURIComponent(title)}`,
        "_blank"
      );
    },
    copyLink: async () => {
      try {
        await navigator.clipboard.writeText(url);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error("Kopyalama başarısız:", err);
      }
    },
  };

  const iconSize = getIconSize();
  const buttonSize = getButtonSize();

  // Sosyal medya butonları için ortak stil
  const commonIconButtonStyle = {
    width: buttonSize,
    height: buttonSize,
    padding: isMobile ? "4px" : "8px",
  };

  return (
    <MDBox
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: { xs: 0.5, sm: 1 },
        alignItems: "center",
        justifyContent: { xs: "flex-start", sm: "flex-start" },
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      <Tooltip title="Facebook'ta Paylaş">
        <IconButton
          onClick={shareHandlers.facebook}
          sx={{
            ...commonIconButtonStyle,
            color: "#1877F2",
          }}
          size={iconSize}
          aria-label="Facebook'ta paylaş"
        >
          <FacebookIcon fontSize={iconSize} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Twitter'da Paylaş">
        <IconButton
          onClick={shareHandlers.twitter}
          sx={{
            ...commonIconButtonStyle,
            color: "#1DA1F2",
          }}
          size={iconSize}
          aria-label="Twitter'da paylaş"
        >
          <TwitterIcon fontSize={iconSize} />
        </IconButton>
      </Tooltip>

      <Tooltip title="WhatsApp'ta Paylaş">
        <IconButton
          onClick={shareHandlers.whatsapp}
          sx={{
            ...commonIconButtonStyle,
            color: "#25D366",
          }}
          size={iconSize}
          aria-label="WhatsApp'ta paylaş"
        >
          <WhatsAppIcon fontSize={iconSize} />
        </IconButton>
      </Tooltip>

      <Tooltip title="LinkedIn'de Paylaş">
        <IconButton
          onClick={shareHandlers.linkedin}
          sx={{
            ...commonIconButtonStyle,
            color: "#0A66C2",
          }}
          size={iconSize}
          aria-label="LinkedIn'de paylaş"
        >
          <LinkedInIcon fontSize={iconSize} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Telegram'da Paylaş">
        <IconButton
          onClick={shareHandlers.telegram}
          sx={{
            ...commonIconButtonStyle,
            color: "#0088cc",
          }}
          size={iconSize}
          aria-label="Telegram'da paylaş"
        >
          <TelegramIcon fontSize={iconSize} />
        </IconButton>
      </Tooltip>

      <Tooltip title={copied ? "Kopyalandı!" : "Linki Kopyala"}>
        <IconButton
          onClick={shareHandlers.copyLink}
          sx={{
            ...commonIconButtonStyle,
            color: copied
              ? theme.palette.success.main
              : darkMode
              ? theme.palette.common.white
              : theme.palette.common.black,
          }}
          size={iconSize}
          aria-label="Linki kopyala"
        >
          <ContentCopyIcon fontSize={iconSize} />
        </IconButton>
      </Tooltip>
    </MDBox>
  );
}

MDSocialShare.defaultProps = {
  url: "",
  title: "",
  size: "medium",
};

MDSocialShare.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default MDSocialShare;
