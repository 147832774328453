import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import {
  Grid,
  Chip,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import {
  GitHub as GitHubIcon,
  LinkedIn as LinkedInIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  Code as CodeIcon,
  Work as WorkIcon,
  School as SchoolIcon,
} from "@mui/icons-material";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

const Hakkimda = () => {
  const [profileData, setProfileData] = useState({
    name: "",
    title: "",
    description: "",
    avatarUrl: "",
    skills: [],
    experiences: [],
    education: [],
    socialLinks: {
      github: "",
      linkedin: "",
      email: ""
    }
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const docRef = doc(db, "blog", "hakkimda");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();

          // Veri dönüşümlerini ve kontrolleri yapalım
          setProfileData({
            name: data.name || "",
            title: data.title || "",
            description: data.description || "",
            avatarUrl: data.avatarUrl || "",
            // skills array kontrolü
            skills: Array.isArray(data.skills) ? data.skills : [],
            // experiences array kontrolü
            experiences: Array.isArray(data.experiences) ? data.experiences : [],
            education: Array.isArray(data.education)
              ? data.education
              : data.education ? [data.education] : [],
            socialLinks: {
              github: data.socialLinks?.github || "",
              linkedin: data.socialLinks?.linkedin || "",
              email: data.socialLinks?.email || ""
            }
          });
        } else {
          setError("Hakkımda bilgileri bulunamadı");
        }
      } catch (err) {
        setError("Veri çekilirken bir hata oluştu");
        console.error("Veri çekme hatası:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  // Yükleme durumu kontrolü
  if (loading) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <MDTypography>Yükleniyor...</MDTypography>
      </MDBox>
    );
  }

  // Hata durumu kontrolü
  if (error) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <MDTypography color="error">{error}</MDTypography>
      </MDBox>
    );
  }

  // experiences array kontrolü
  const experiences = Array.isArray(profileData.experiences) ? profileData.experiences : [];
  // skills array kontrolü
  const skills = Array.isArray(profileData.skills) ? profileData.skills : [];

  return (
    <>
      {/* Hakkımda Profil Kartı */}
      <MDBox elevation={3} p={3} mb={4} borderRadius="xl">
        <Grid container spacing={4} alignItems="center">
          <Grid item>
            {profileData.avatarUrl ? (
              // Avatar URL'si varsa resmi göster
              <MDAvatar
                src={profileData.avatarUrl}
                alt={profileData.name}
                sx={{
                  width: 100,
                  height: 100,
                  border: "2px solid",
                  borderColor: "primary.main"
                }}
              />
            ) : (
              // Avatar URL'si yoksa PersonIcon'u göster
              <MDAvatar
                sx={{
                  width: 100,
                  height: 100,
                  bgcolor: "primary.main",
                }}
              >
                <PersonIcon sx={{ fontSize: 50 }} />
              </MDAvatar>
            )}
          </Grid>
          <Grid item xs>
            <MDTypography variant="h3" gutterBottom>
              {profileData.name}
            </MDTypography>
            <MDTypography variant="h6" gutterBottom>
              {profileData.title}
            </MDTypography>
            <MDBox sx={{ mt: 2 }}>
              {profileData.socialLinks?.github && (
                <MDButton
                  variant="outlined"
                  color="primary"
                  startIcon={<GitHubIcon />}
                  sx={{ mr: 2 }}
                  href={profileData.socialLinks.github}
                  target="_blank"
                >
                  GitHub
                </MDButton>
              )}
              {profileData.socialLinks?.linkedin && (
                <MDButton
                  variant="outlined"
                  color="primary"
                  startIcon={<LinkedInIcon />}
                  sx={{ mr: 2 }}
                  href={profileData.socialLinks.linkedin}
                  target="_blank"
                >
                  LinkedIn
                </MDButton>
              )}
              {profileData.socialLinks?.email && (
                <MDButton
                  variant="outlined"
                  color="primary"
                  startIcon={<EmailIcon />}
                  href={`mailto:${profileData.socialLinks.email}`}
                >
                  İletişim
                </MDButton>
              )}
            </MDBox>
          </Grid>
        </Grid>
        <MDTypography variant="body1" sx={{ mt: 4 }}>
          {profileData.description}
        </MDTypography>
      </MDBox>

      {/* Yetenekler Kartı */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <MDBox sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <CodeIcon sx={{ mr: 1 }} />
            <MDTypography variant="h5">Teknik Yetenekler</MDTypography>
          </MDBox>
          <MDBox sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {skills.map((skill, index) => (
              <Chip
                key={index}
                label={skill}
                variant="outlined"
                color="primary"
              />
            ))}
          </MDBox>
        </CardContent>
      </Card>

      {/* Deneyim Kartı */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <MDBox sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <WorkIcon sx={{ mr: 1 }} />
            <MDTypography variant="h5">İş Deneyimi</MDTypography>
          </MDBox>
          {experiences.map((exp, index) => (
            <MDBox key={index} sx={{ mb: 3 }}>
              <MDTypography variant="h6" gutterBottom>
                {exp.title}
              </MDTypography>
              <MDTypography variant="subtitle1" gutterBottom>
                {exp.company} | {exp.period}
              </MDTypography>
              <MDTypography variant="body2">{exp.description}</MDTypography>
              {index !== experiences.length - 1 && <Divider sx={{ my: 2 }} />}
            </MDBox>
          ))}
        </CardContent>
      </Card>

      {/* Eğitim Kartı */}
      <Card>
        <CardContent>
          <MDBox sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <SchoolIcon sx={{ mr: 1 }} />
            <MDTypography variant="h5">Eğitim</MDTypography>
          </MDBox>
          {profileData.education.map((edu, index) => (
            <MDBox key={index} sx={{ mb: 3 }}>
              <MDTypography variant="h6" gutterBottom>
                {edu.degree}
              </MDTypography>
              <MDTypography variant="subtitle1" gutterBottom>
                {edu.school} | {edu.period}
              </MDTypography>
              {edu.description && (
                <MDTypography variant="body2">
                  {edu.description}
                </MDTypography>
              )}
              {/* Add divider if not the last item */}
              {index !== profileData.education.length - 1 && (
                <Divider sx={{ my: 2 }} />
              )}
            </MDBox>
          ))}
        </CardContent>
      </Card>
    </>
  );
};

export default Hakkimda;