// src/services/postService.js
import { db } from "lib/firebase";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  serverTimestamp,
  limit,
} from "firebase/firestore";
import { generateSlug } from "utils";

export const postService = {
  // Post oluşturma
  async createPost(postData, userId) {
    try {
      const post = {
        ...postData,
        authorId: userId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        slug: generateSlug(postData.title),
      };

      const docRef = await addDoc(collection(db, "posts"), post);
      return docRef.id;
    } catch (error) {
      console.error("Post oluşturma hatası:", error);
      throw error;
    }
  },

  // Post güncelleme
  async updatePost(postId, postData) {
    try {
      const postRef = doc(db, "posts", postId);
      await updateDoc(postRef, {
        ...postData,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error("Post güncelleme hatası:", error);
      throw error;
    }
  },

  // Post silme
  async deletePost(postId) {
    try {
      const postRef = doc(db, "posts", postId);
      await deleteDoc(postRef);
    } catch (error) {
      console.error("Post silme hatası:", error);
      throw error;
    }
  },

  // Tüm postları getirme
  async getPosts() {
    try {
      const q = query(
        collection(db, "posts"),
        where("status", "==", "published"),
        orderBy("createdAt", "desc")
      );

      const postsSnapshot = await getDocs(q);
      const postsPromises = postsSnapshot.docs.map(async (postDoc) => {
        const postData = postDoc.data();
        const authorRef = doc(db, "users", postData.authorId);
        const authorSnapshot = await getDoc(authorRef);
        const authorData = authorSnapshot.data();

        return {
          id: postDoc.id,
          ...postData,
          authorAvatar:
            authorData?.avatarUrl ||
            "https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80",
        };
      });

      return Promise.all(postsPromises);
    } catch (error) {
      console.error("Posts getirme hatası:", error);
      throw error;
    }
  },

  // Tek post getirme
  async getPostBySlug(slug) {
    try {
      const q = query(
        collection(db, "posts"),
        where("slug", "==", slug),
        limit(1)
      );

      const snapshot = await getDocs(q);
      if (snapshot.empty) return null;

      const doc = snapshot.docs[0];
      return {
        id: doc.id,
        ...doc.data(),
      };
    } catch (error) {
      console.error("Post getirme hatası:", error);
      throw error;
    }
  },

  // Öne çıkan yazıları getiren fonksiyon güncellendi
  getFeaturedPosts: async () => {
    try {
      const postsRef = collection(db, "posts");
      // Status kontrolü öncelikli, isFeatured sonra kontrol ediliyor
      const q = query(
        postsRef,
        where("status", "==", "published"),
        orderBy("publishedAt", "desc"),
        limit(8) // Daha fazla post alıyoruz
      );

      const querySnapshot = await getDocs(q);
      const allPosts = [];

      // Yazar bilgilerini paralel olarak getirme
      const postsPromises = querySnapshot.docs.map(async (postDoc) => {
        const postData = postDoc.data();

        // Sadece öne çıkan gönderileri filtreleme
        if (!postData.isFeatured) return null;

        // Yazar bilgilerini getirme
        let authorData = {};
        try {
          const authorDoc = await getDoc(doc(db, "users", postData.authorId));
          if (authorDoc.exists()) {
            authorData = authorDoc.data();
          }
        } catch (error) {
          console.warn("Author data fetch failed:", error);
        }

        return {
          id: postDoc.id,
          ...postData,
          authorName: authorData.fullName || "Anonim",
          authorAvatar:
            authorData.avatarUrl || "https://via.placeholder.com/150",
        };
      });

      const resolvedPosts = await Promise.all(postsPromises);
      // null değerleri filtreleme ve limit uygulama
      const featuredPosts = resolvedPosts
        .filter((post) => post !== null)
        .slice(0, 4); // İlk 4 öne çıkan gönderiyi al

      return featuredPosts;
    } catch (error) {
      console.error("Featured posts fetch error:", error);
      return []; // Hata durumunda boş dizi dön
    }
  },
};
