import React, { createContext, useState, useEffect, useContext } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import PropTypes from "prop-types";
import MDLoadingScreen from "components/MDLoadingScreen";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";

const AuthContext = createContext(null); // Başlangıç değeri ekledik

export function AuthProvider({ children }) {
  const [state, setState] = useState({
    user: null,
    loading: true,
    error: null,
  });

  const auth = getAuth();
  const db = getFirestore();

  // AuthContext'teki useEffect hook'unu da güncelleyelim
  useEffect(() => {
    let mounted = true;

    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        if (!mounted) return;

        if (user) {
          // Kullanıcı durumunu yenile
          await user.reload();

          // Firestore'dan kullanıcı verilerini kontrol et
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);

          // Firebase Auth'daki e-posta doğrulama durumunu kullan
          // ve Firestore'u buna göre güncelle
          if (user.emailVerified && userDoc.exists()) {
            await updateDoc(userRef, {
              emailVerified: true,
            });
          }

          setState({
            user: {
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              photoURL: user.photoURL,
              emailVerified: user.emailVerified, // Firebase Auth'daki durumu kullan
            },
            loading: false,
            error: null,
          });
        } else {
          setState({
            user: null,
            loading: false,
            error: null,
          });
        }
      },
      (error) => {
        if (!mounted) return;

        console.error("Auth error:", error);
        setState({
          user: null,
          loading: false,
          error: error.message,
        });
      }
    );

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  const logout = async () => {
    try {
      setState((prev) => ({ ...prev, loading: true }));
      await signOut(auth);
      setState({
        user: null,
        loading: false,
        error: null,
      });
    } catch (error) {
      console.error("Logout error:", error);
      setState((prev) => ({
        ...prev,
        loading: false,
        error: error.message,
      }));
      throw error;
    }
  };

  const value = {
    user: state.user,
    loading: state.loading,
    error: state.error,
    logout,
  };

  if (state.loading) {
    return <MDLoadingScreen text="Bir saniye..." />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
