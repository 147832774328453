import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Link,
  Stack,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { GitHub, Twitter, LinkedIn } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Footer({
  company,
  links,
  socialLinks = defaultSocialLinks,
  isMainLayout,
}) {
  const { href, name } = company;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Check if the link is external
  const isExternalLink = (url) => {
    return url.startsWith("http") || url.startsWith("https");
  };

  // Render navigation links
  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" sx={{ display: "inline" }}>
        {isExternalLink(link.href) ? (
          <Link
            href={link.href}
            target="_blank"
            sx={{
              textDecoration: "none",
              transition: "color 0.2s",
              "&:hover": {
                color: "primary.main",
              },
            }}
          >
            {link.name}
          </Link>
        ) : (
          <Link
            component={RouterLink}
            to={link.href}
            sx={{
              textDecoration: "none",
              transition: "color 0.2s",
              "&:hover": {
                color: "primary.main",
              },
            }}
          >
            {link.name}
          </Link>
        )}
      </MDBox>
    ));

  // Render social media icons
  const renderSocialLinks = () =>
    socialLinks.map((social) => (
      <Link
        key={social.name}
        href={social.href}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          transition: "color 0.2s, transform 0.2s",
          "&:hover": {
            color: "primary.main",
            transform: "translateY(-2px)",
          },
        }}
      >
        {social.icon}
      </Link>
    ));

  return (
    <MDBox
      component="footer"
      sx={
        isMainLayout
          ? {
              py: 1,
              px: 2,
              mt: "auto",
            }
          : {
              py: 0.5,
              px: 0.5,
              mt: "auto",
            }
      }
    >
      {/* <Container maxWidth="xxl"> */}
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems={isMobile ? "center" : "flex-start"}
        spacing={4}
      >
        {/* Company Info */}
        {isMainLayout ? (
          <Stack spacing={2} alignItems={isMobile ? "center" : "flex-start"}>
            <MDTypography variant="h6" gutterBottom>
              {name}
            </MDTypography>
            <MDTypography variant="body2">
              Building better web experiences
            </MDTypography>
            <Stack direction="row" spacing={2}>
              {renderSocialLinks()}
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={2} alignItems={isMobile ? "center" : "flex-start"}>
            <Stack direction="row" spacing={2}>
              {renderSocialLinks()}
            </Stack>
          </Stack>
        )}

        {/* Navigation Links */}
        <Stack
          component="nav"
          direction={isMobile ? "row" : isMainLayout ? "column" : "row"}
          alignItems={isMobile ? "center" : "flex-end"}
          spacing={2}
          sx={{
            "& li": {
              listStyle: "none",
            },
          }}
        >
          {renderLinks()}
        </Stack>
      </Stack>

      <Divider sx={{ my: 2 }} />

      {/* Bottom Section */}
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <MDTypography variant="body2">
          © {new Date().getFullYear()} {name}. All rights reserved.
        </MDTypography>
        <MDBox display="flex" justifyContent="center" alignItems="bottom">
          <a
            href="//www.dmca.com/Protection/Status.aspx?ID=be625cc3-45ae-4c01-a232-59eab2bfd122"
            title="DMCA.com Protection Status"
            className="dmca-badge"
          >
            {" "}
            <img
              src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-10.png?ID=be625cc3-45ae-4c01-a232-59eab2bfd122"
              alt="DMCA.com Protection Status"
            />
          </a>{" "}
          <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js">
            {" "}
          </script>
        </MDBox>
        <MDTypography variant="caption" align="center">
          Made with Material UI
        </MDTypography>
      </Stack>
      {/* </Container> */}
    </MDBox>
  );
}

// Default social media links
const defaultSocialLinks = [
  {
    name: "GitHub",
    href: "https://github.com/gkhnmr",
    icon: <GitHub fontSize="small" />,
  },
  {
    name: "Twitter",
    href: "https://x.com/gkhnmr",
    icon: <Twitter fontSize="small" />,
  },
  {
    name: "LinkedIn",
    href: "https://linkedin.com/in/gkhnmr",
    icon: <LinkedIn fontSize="small" />,
  },
];

Footer.propTypes = {
  company: PropTypes.shape({
    href: PropTypes.string,
    name: PropTypes.string,
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    })
  ),
  isMainLayout: PropTypes.bool,
};

Footer.defaultProps = {
  company: { href: "/", name: "Your Company" },
  links: [
    { href: "/", name: "Home" },
    { href: "/about", name: "About" },
    { href: "/blog", name: "Blog" },
    { href: "/contact", name: "Contact" },
  ],
  isMainLayout: false,
};

export default Footer;
