// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import Footer from "examples/Footer";

import { useAccount } from "context/AccountContext";
import MDLoadingSkeleton from "components/MDLoadingSkeleton";
import MDErrorDisplay from "components/MDErrorDisplay";
import PropTypes from "prop-types";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import { usePosts } from "hooks/usePosts";

function PostsCategoryLayout({ transparent, categoryId }) {
  const { isLoading, error } = useAccount();
  const { postsCategory } = usePosts(categoryId);

  // Loading durumunda tüm grid için tek bir loading state
  if (isLoading) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <Grid container spacing={2}>
          {[...Array(3)].map((_, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <MDLoadingSkeleton />
            </Grid>
          ))}
        </Grid>
        <Footer />
      </MDBox>
    );
  }

  // Hata durumunda
  if (error) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <MDErrorDisplay message={error} />
      </MDBox>
    );
  }

  const renderCategoryPosts = () => {
    return postsCategory.map((post) => (
      <Grid item key={post.id} xs={12} md={6} lg={4} mb={3}>
        <DefaultProjectCard
          image={
            post.featuredImageUrl ||
            "https://images.unsplash.com/photo-1497294815431-9365093b7371?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
          }
          label={post.categoryName}
          title={post.title}
          description={post.excerpt}
          action={{
            route: `/post/${post.slug}`,
            label: "detay",
            color: "info",
            type: "internal",
          }}
          authors={[
            {
              image: post.authorAvatar,
              name: post.authorName,
            },
          ]}
        />
      </Grid>
    ));
  };

  return (
    <>
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <MDBox mb={3}>
          <Grid container spacing={2}>
            {renderCategoryPosts()}
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
}

PostsCategoryLayout.defaultProps = {
  transparent: false,
  light: false,
  categoryId: null,
};

PostsCategoryLayout.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  categoryId: PropTypes.string,
};

export default PostsCategoryLayout;
